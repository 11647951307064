import React from 'react'

import SEO from '@/components/seo'

import Home from './home'

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Home />
  </>
)

export default IndexPage
